import { useEffect } from "react";
import FAB from "./FAB";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Layout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-lblue-500">
      <header>
        <Navbar />
      </header>
      <main>{children}</main>
      <Footer />
      <FAB />
    </div>
  );
}
