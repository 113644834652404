import { useEffect, useState } from "react";
import { BsWhatsapp } from "react-icons/bs";

export default function FAB() {
  const [show, setShow] = useState(false);

  const onClickChatUs = () => {
    window.open("https://wa.me/+6281381399821", "_blank");
    window.focus();
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1000) {
        return setShow(true);
      }
      return setShow(false);
    });
  }, []);

  return (
    <button
      className={`${
        show ? "opacity-100 scale-100" : "opacity-0 scale-0"
      } py-3 px-4 lg:p-5 bg-wa-500 rounded-full fixed bottom-[2rem] right-[calc(0px_+_16px)] lg:right-[calc(0px_+_32px)] group shadow-lg transition-all duration-300 flex items-center gap-4`}
      onClick={onClickChatUs}
    >
      <BsWhatsapp className="text-3xl lg:text-4xl text-white group-hover:scale-90 transition-all duration-150" />
      <span className="text-lg text-white font-semibold font-poppins">
        Chat Us!
      </span>
    </button>
  );
}
