import { Link } from "react-router-dom";
import IPMLogo from "../assets/images/ipm-logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

export default function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div
          className={`w-full lg:hidden absolute top-0 pt-24 z-50 py-4 px-4 bg-lblue-500 left-0 flex flex-col gap-8 ${
            open ? "translate-y-0" : "-translate-y-full"
          } transition-all duration-300 shadow-md lg:shadow-none`}
        >
          <ul className="flex flex-col w-full h-full mx-auto text-sm font-montserrat">
            <Link to="/">
              <li className="cursor-pointer w-full text-center py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/4 after:transition-all after:duration-150">
                BERANDA
              </li>
            </Link>
            <Link to="/profil">
              <li className="cursor-pointer w-full text-center py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/4 after:transition-all after:duration-150">
                PROFIL
              </li>
            </Link>
            <Link to="/universitas">
              <li className="cursor-pointer w-full text-center py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/4 after:transition-all after:duration-150">
                UNIVERSITAS
              </li>
            </Link>
            <Link to="/kontak">
              <li className="cursor-pointer w-full text-center py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/4 after:transition-all after:duration-150">
                KONTAK
              </li>
            </Link>
          </ul>
          <button className="px-3 py-2 bg-pblue-500 font-poppins text-white text-sm rounded-md hover:scale-110 transition-all duration-300">
            Daftar Sekarang
          </button>
        </div>
      </OutsideClickHandler>
      <nav
        className={`w-full bg-lblue-500 flex items-center px-6 md:px-16 lg:px-24 py-4 justify-between z-50 absolute top-0 ${
          open && "shadow-none"
        } shadow-md text-slate-500 transition-all duration-300`}
      >
        <Link to="/">
          <img src={IPMLogo} alt="IPM Logo" className="h-10" />
        </Link>
        <ul className="hidden lg:flex h-full mx-auto text-sm font-montserrat">
          <Link to="/">
            <li className="cursor-pointer py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/2 after:transition-all after:duration-150">
              BERANDA
            </li>
          </Link>
          <Link to="/profil">
            <li className="cursor-pointer py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/2 after:transition-all after:duration-150">
              PROFIL
            </li>
          </Link>
          <Link to="/universitas">
            <li className="cursor-pointer py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/2 after:transition-all after:duration-150">
              UNIVERSITAS
            </li>
          </Link>
          <Link to="/kontak">
            <li className="cursor-pointer py-4 px-6 relative after:contents-['_'] after:absolute after:h-[3px] after:bg-pblue-500 after:w-0 after:top-[70%] after:left-1/2 after:-translate-x-1/2 after:hover:w-1/2 after:transition-all after:duration-150">
              KONTAK
            </li>
          </Link>
        </ul>
        <button className="hidden lg:block px-3 py-2 bg-pblue-500 font-poppins text-white text-sm rounded-md hover:scale-110 transition-all duration-300">
          Daftar Sekarang
        </button>
        <button onClick={() => setOpen(!open)}>
          <RxHamburgerMenu className="lg:hidden text-pblue-500 w-6 h-6" />
        </button>
      </nav>
    </>
  );
}
