import { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Layout from "../components/Layout";
import UniversitiesList from "../data/universities";

export default function Universities() {
  const universities = UniversitiesList;

  const [activeIndex, setActiveIndex] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const carousel = document.getElementById("carousel");
    let scrollVal = 0;

    if (width <= 426) {
      scrollVal = 384;
    } else if (width > 426 && width <= 768) {
      scrollVal = 597;
    } else if (width > 768 && width <= 1024) {
      scrollVal = 640;
    } else if (width > 1024) {
      scrollVal = 900;
    }

    carousel.scrollTo(activeIndex * scrollVal, 0);
  }, [activeIndex, width]);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [width]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = universities.length - 1;
    } else if (newIndex >= universities.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  return (
    <Layout>
      <section className="pt-32 lg:pt-36 relative overflow-x-hidden overflow-y-hidden pb-8">
        <h1 className="w-5/6 lg:w-4/6 mx-auto font-montserrat text-center font-semibold text-slate-700 text-3xl relative z-30">
          Temukan <span className="text-pblue-500">Universitas Impian</span>{" "}
          Anda Bersama Kami
        </h1>
        <div className="relative">
          <div className="hidden lg:block w-[calc(100vw_+_500px)] h-28 rounded-[50%] bg-lblue-500 absolute z-10 lg:-top-[30%] xl:-top-[22%] left-1/2 -translate-x-1/2"></div>
          <div className="hidden lg:block w-[calc(100vw_+_500px)] h-28 rounded-[50%] bg-lblue-500 absolute z-10 lg:top-[90%] xl:top-[91%] left-1/2 -translate-x-1/2"></div>
          <div
            className="h-full flex gap-4 xl:gap-8 overflow-x-scroll my-14 px-8 md:px-[150px] lg:px-[320px] xl:px-[600px] relative items-center snap-x scroll-smooth"
            id="carousel"
          >
            {universities.map((univ, i) => (
              <div
                key={i}
                className="aspect-video h-[200px] md:h-[300px] xl:h-[400px] relative flex justify-center items-center text-3xl text-white snap-center"
              >
                <img
                  src={univ.img}
                  alt=""
                  className="relative w-full h-full object-cover z-0"
                />
                <div className="w-full h-1/2 bottom-0 absolute bg-gradient-to-b from-transparent to-[rgba(0,0,0,0.7)] z-[1]"></div>
                <h1 className="absolute top-[70%] text-3xl md:text-4xl xl:text-6xl  z-[2]">
                  {univ.kota}
                </h1>
              </div>
            ))}
          </div>
          <button
            className="bg-transparent px-4 py-4 opacity-60 text-pblue-500 absolute z-20 top-1/2 left-2 md:left-6 -translate-y-1/2 rounded-full hover:bg-white hover:bg-opacity-70 hover:opacity-100 transition-all duration-150"
            onClick={() => updateIndex(activeIndex - 1)}
          >
            <FaArrowLeft className="text-2xl" />
          </button>
          <button
            className="bg-transparent px-4 py-4 opacity-60 text-pblue-500 absolute z-20 top-1/2 right-2 md:right-6 -translate-y-1/2 rounded-full hover:bg-white hover:bg-opacity-70 hover:opacity-100 transition-all duration-150"
            onClick={() => updateIndex(activeIndex + 1)}
          >
            <FaArrowRight className="text-2xl" />
          </button>
        </div>
      </section>
    </Layout>
  );
}
