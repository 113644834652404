export default function EmailModal({ modalOpen, setModalOpen }) {
  return (
    <div
      className={`${
        modalOpen
          ? "opacity-100 visible translate-y-8"
          : "opacity-0 invisible -translate-y-8"
      } bg-white min-w-max p-8 rounded-md fixed z-30 top-[20%] left-1/2 -translate-x-1/2 flex flex-col gap-8 shadow-lg items-center transition-all duration-150`}
    >
      <p className="text-pblue-500 text-2xl text-center">
        Sukses mengirim email
      </p>
      <button
        onClick={() => setModalOpen(false)}
        className="w-full border-2 rounded-full py-2 border-slate-300 hover:bg-slate-300 hover:text-white transition-all duration-150"
      >
        Tutup
      </button>
    </div>
  );
}
