export default function UniversityHomeCard({ kota, univImg }) {
  return (
    <div className="flex flex-col gap-6 items-center snap-center">
      <img
        src={univImg}
        alt="universities"
        className="aspect-[3/4] w-56 object-cover"
      />
      <h3 className="font-montserrat text-pblue-500 text-lg font-semibold">
        {kota}
      </h3>
    </div>
  );
}
