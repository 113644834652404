import IstanbulImg from "../assets/images/universities/istanbul.jpg";

const UniversitiesList = [
    {
        nama: "",
        kota: "Istanbul",
        negara: "Turki",
        img: IstanbulImg,
    }
]

export default UniversitiesList;