import { Route, Routes } from "react-router-dom";
import Contactpage from "./pages/Contactpage";
import Homepage from "./pages/Homepage";
import Aboutpage from "./pages/Aboutpage";
import Universities from "./pages/Universitiespage";

export default function IpmApp() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/profil" element={<Aboutpage />} />
      <Route path="/universitas" element={<Universities />} />
      <Route path="/kontak" element={<Contactpage />} />
    </Routes>
  );
}
