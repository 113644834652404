import academicCap from "../assets/images/services/academic-cap.png";
import bookmark from "../assets/images/services/bookmark.png";
import exchange from "../assets/images/services/exchange.png";
import paper from "../assets/images/services/paper.png";
import tour from "../assets/images/services/tour.png";
import visa from "../assets/images/services/visa.png";

export default function ServiceItem({ service, index }) {
  const icon = [academicCap, paper, bookmark, exchange, visa, tour];

  return (
    <li className="flex flex-col items-center gap-2">
      <div className="bg-white rounded-full p-4">
        <img
          src={icon[index]}
          alt={`service-${index + 1}`}
          className="w-8 h-8"
        />
      </div>
      <p className="font-montserrat font-semibold text-pblue-500 text-center md:text-lg lg:text-xl md:w-2/3">
        {service}
      </p>
    </li>
  );
}
