import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { SiGmail } from "react-icons/si";
import { Link } from "react-router-dom";
import IPMLogo from "../assets/images/ipm-logo.png";
import OrnB from "../assets/images/ornamen-blue.png";
import UniversitiesList from "../data/universities";

export default function Footer() {
  const kota = UniversitiesList.map((univ) => univ.kota);

  return (
    <footer className="bg-white px-6 md:px-14 lg:px-24 py-14 flex flex-col sm:flex-row relative overflow-clip">
      <img
        src={OrnB}
        alt="oranamen"
        className="absolute opacity-30 -scale-x-100 right-0 bottom-0 sm:w-[500px] md:w-[500px] lg:w-[700px] translate-x-40"
      />
      <div className="flex flex-col basis-1/2 gap-6">
        <div className="flex flex-col gap-3 items-start">
          <img src={IPMLogo} alt="imp-logo" />
          <p className="w-72 text-slate-500 font-poppins">
            Lembaga Konsultan Pendidikan Profesional
          </p>
          <Link
            to="/profil"
            className="font-poppins text-pblue-500 font-semibold"
          >
            Profil
          </Link>
        </div>
        <div className="flex flex-col gap-3 items-start">
          <p className="w-80 font-poppins text-slate-500">
            Gedung Graha Mampang, Lt. 3 Suite. 305 Jl. Mampang Prapatan Raya
            Kav. 100 Pancoran, Jakarta Selatan
          </p>
          <Link
            to="/kontak"
            className="font-poppins text-pblue-500 font-semibold"
          >
            Kontak
          </Link>
        </div>
        <ul className="flex text-2xl text-white gap-6">
          <a
            href="https://instagram.com/insanpelajarmektebi?igshid=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noreferrer"
          >
            <li className="bg-pblue-500 p-4 rounded-full cursor-pointer group transition-all">
              <BsInstagram className="group-hover:scale-[80%] transition-all duration-150" />
            </li>
          </a>
          <a
            href="https://wa.me/+6281381399821"
            target="_blank"
            rel="noreferrer"
          >
            <li className="bg-pblue-500 p-4 rounded-full cursor-pointer group transition-all">
              <BsWhatsapp className="group-hover:scale-[80%] transition-all duration-150" />
            </li>
          </a>
          <a href="mailto:admin@insanpelajarmektebi.com">
            <li className="bg-pblue-500 p-4 rounded-full cursor-pointer group transition-all">
              <SiGmail className="group-hover:scale-[80%] transition-all duration-150" />
            </li>
          </a>
        </ul>
      </div>
      <ul className="font-poppins text-pblue-500 font-semibold text-lg grid grid-cols-2 gap-4 text-center justify-center items-center basis-1/2 pt-14 md:items-start md:py-28">
        {kota.map((kota, i) => (
          <Link to="/universitas">
            <li key={i}>{kota}</li>
          </Link>
        ))}
      </ul>
    </footer>
  );
}
