import Layout from "../components/Layout";
import Ornw from "../assets/images/ornamen-white.png";
import Ornb from "../assets/images/ornamen-blue.png";
import { MdArrowForward } from "react-icons/md";
import ServiceItem from "../components/ServiceItem";
import UniversityHomeCard from "../components/UniversityHomeCard";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import UniversitiesList from "../data/universities";
import Badge1 from "../assets/images/badge-1.png";
import Badge2 from "../assets/images/badge-2.png";
import Badge3 from "../assets/images/badge-3.png";
import Badge4 from "../assets/images/badge-4.png";

export default function Homepage() {
  const [activeIndex, setActiveIndex] = useState(0);

  const services = [
    "Konsultasi dan Pengurusan Kuliah",
    "Legalisir Dokumen Kementerian",
    "Terjemah Dokumen Sumpah",
    "Kelas Bahasa Turki",
    "Pengurusan Visa",
    "Konsultan Perjalanan Tour",
  ];

  const univs = UniversitiesList;

  useEffect(() => {
    const carousel = document.getElementById("univ-carousel");

    carousel.scrollTo(activeIndex * 250, 0);
  }, [activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = univs.length - 1;
    } else if (newIndex >= univs.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  return (
    <Layout>
      <section
        className="w-full h-screen flex flex-col justify-center items-center bg-slate-400 font-montserrat text-white font-semibold"
        style={{
          backgroundImage: "url('/assets/img/turkiye.webp')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-center text-3xl lg:text-4xl">
          Insan Pelajar Mektebi
        </h1>
        <h1 className="max-w-xs lg:max-w-2xl text-center text-2xl lg:text-4xl mt-4">
          Edu Consultant terpercaya untuk masa depan anda
        </h1>
      </section>
      <div className="relative z-30 w-full mx-auto md:w-4/5 lg:w-1/2 transition-all">
        <div className="w-full md:w-[calc(100%_+_70px)] h-[168px] md:h-[156px] lg:h-[172px] bg-dblue-500 left-1/2 -translate-x-1/2 absolute"></div>
        <div className="bg-white w-full py-8 px-4 absolute -translate-y-[42%] left-1/2 -translate-x-1/2">
          <h2 className="text-center font-montserrat text-pblue-500 text-2xl lg:text-3xl xl:text-4xl font-semibold">
            Temukan Kampus Impianmu
          </h2>
          <ul className="flex w-full justify-around mt-10">
            <li className="aspect-square w-20 lg:w-24 rounded-full">
              <img src={Badge1} alt="" className="w-full h-full" />
            </li>
            <li className="aspect-square w-20 lg:w-24 rounded-full">
              <img src={Badge2} alt="" className="w-full h-full" />
            </li>
            <li className="aspect-square w-20 lg:w-24 rounded-full">
              <img src={Badge3} alt="" className="w-full h-full" />
            </li>
            <li className="aspect-square w-20 lg:w-24 rounded-full">
              <img src={Badge4} alt="" className="w-full h-full" />
            </li>
          </ul>
        </div>
      </div>
      <section className="w-full pt-56 pb-24 px-4 md:px-16 lg:px-24 relative bg-lblue-500 transition-all">
        <div className="w-full h-96 bg-dblue-500 absolute bottom-0 left-0 flex justify-end overflow-x-clip">
          <img
            src={Ornw}
            alt="ornamen"
            className="h-[350px] self-center absolute -right-16"
          />
        </div>
        <div className="flex flex-col lg:flex-row relative justify-between">
          <img
            src="/assets/img/img-1.webp"
            alt="graduate"
            className="w-full lg:w-1/2 object-cover"
          />
          <div className="basis-1/2 p-8 lg:py-24 lg:px-10 bg-white">
            <hr className="mb-4 lg:mb-8 w-[20%] border-[2px] bg-pblue-500 border-pblue-500" />
            <h2 className="font-montserrat text-pblue-500 text-3xl lg:text-4xl font-semibold">
              Tentang Kita
            </h2>
            <p className="mt-4 font-poppins text-slate-500 text-justify text-sm lg:leading-8 lg:text-base">
              Insan Pelajar Mektebi merupakan lembaga konsultan pendidikan
              profesional yang dirintis oleh alumni berpengalaman yang berfokus
              pada jenjang pendidikan menengah ke atas (SMA), perguruan tinggi
              program Sarjana, Master maupun Doktoral di Turki.
            </p>
            <Link to="/profil">
              <button className="text-pblue-500 mt-6 hover:translate-x-4 transition-all duration-300">
                <MdArrowForward className="w-8 h-8 md:w-10 md:h-10 lg:w-16 lg:h-16" />
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-white pt-20 md:pt-14 pb-14 md:px-14 lg:px-24 flex flex-col lg:flex-row gap-8 lg:gap-0 mt-16 transition-all">
        <img
          src="/assets/img/img-2.webp"
          alt="library"
          className="w-full lg:w-1/2 object-cover"
        />
        <div className="basis-1/2 flex flex-col justify-start px-8 md:px-0 lg:px-10 lg:pt-24">
          <h2 className="text-4xl font-montserrat text-lblue-500 bg-dblue-500 w-fit p-2 font-semibold">
            Kenapa
          </h2>
          <h2 className="font-montserrat font-semibold text-4xl text-pblue-500 mt-2 mb-6">
            Insan Pelajar Mektebi
          </h2>
          <p className="font-poppins text-slate-500 lg:leading-8 text-justify">
            Tentu saja dalam memilih konsultan pendidikan penting bagi Anda
            memastikan latar belakang seperti sepak terjang dan kelengkapan
            informasi agar menghasilkan layanan yang terbaik. IPM memiliki tim
            yang berpengalaman dan merupakan lulusan dari PTN di Turki. Sehingga
            bisa dijamin pengetahuan yang mendalam dan mumpuni dalam menjalankan
            bisnisnya dan memberikan pelayanan yang baik kepada clientnya. IPM
            sebagai konsultan pendidikan Turki memiliki tim konselor yang
            professional dan siap memberikan konsultasi serta layanan terbaik
            untuk para client. IPM juga memiliki tim khusus untuk membantu kamu
            mengurus pengajuan visa ke kedutaan negara Turki. Jadi, kamu tak
            perlu repot lagi untuk mengurus segala sesuatu terkait kuliah di
            Turki.
          </p>
        </div>
      </section>
      <section className="w-full bg-lblue-500 flex flex-col py-14 px-4 md:px-16 lg:px-24 items-center transition-all">
        <h2 className="font-montserrat font-semibold text-pblue-500 text-4xl">
          Layanan Kami
        </h2>
        <ul className="grid grid-cols-2 lg:grid-cols-3 mt-16 gap-8">
          {services.map((service, i) => (
            <ServiceItem key={i} service={service} index={i} />
          ))}
        </ul>
      </section>
      <section className="w-full bg-lblue-500 py-14 flex flex-col items-center gap-8 relative transition-all">
        <div className="w-full bg-pblue-500 absolute h-1/2 translate-y-1/2 px-24 overflow-clip md:overflow-visible">
          <img
            src={Ornb}
            alt="ornamen"
            className="w-[600px] absolute bottom-2 left-8"
          />
        </div>
        <div className="w-full bg-dblue-500 absolute h-1/2 translate-y-1/2 px-24 overflow-clip">
          <img
            src={Ornw}
            alt="ornamen"
            className="w-[600px] bottom-2 absolute left-8"
          />
        </div>
        <h2 className="font-montserrat font-semibold text-pblue-500 text-4xl relative mb-4">
          Universitas
        </h2>
        <div className="w-full flex flex-col gap-8 py-8 pb-8 relative">
          <div className="w-full md:w-3/4 lg:w-4/5 xl:w-3/5 h-2/5 md:h-3/4 lg:h-2/4 absolute bg-white bottom-0 left-1/2 -translate-x-1/2"></div>
          <div
            className="w-full flex md:flex-wrap px-36 md:px-8 md:justify-center gap-8 relative overflow-x-scroll md:overflow-clip scroll-smooth snap-x"
            id="univ-carousel"
          >
            {univs.map((univ, i) => (
              <UniversityHomeCard key={i} kota={univ.kota} univImg={univ.img} />
            ))}
          </div>
          <Link to="/universitas" className="self-center">
            <button className="px-10 py-2 bg-dblue-500 font-poppins text-white self-center rounded-md relative hover:scale-110 transition-all duration-150">
              Universitas
            </button>
          </Link>
        </div>
      </section>
    </Layout>
  );
}
