import emailjs from "@emailjs/browser";
import { useState } from "react";
import OrnB from "../assets/images/ornamen-blue.png";
import WALogo from "../assets/images/whatsapp-logo.png";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { EMAILJSKEY } from "../key/emailjs";
import EmailModal from "../components/EmailModal";

export default function Contactpage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onClickChatOnWaHandler = () => {
    window.open("https://wa.me/+6281381399821", "_blank");
    window.focus();
  };

  const onSubmitEmail = (ev) => {
    setLoading(true);
    ev.preventDefault();

    try {
      emailjs
        .send(
          EMAILJSKEY.SERVICE_ID,
          EMAILJSKEY.TEMPLATE_ID,
          {
            from_name: name,
            sender_email: email,
            message: msg,
          },
          EMAILJSKEY.PUBLIC_KEY
        )
        .then((res) => {
          setLoading(false);
          setModalOpen(true);
          setName("");
          setEmail("");
          setMsg("");
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (err) {
    } finally {
      setTimeout(() => {
        setLoading(false);
        setModalOpen(false);
      }, 5000);
    }
  };

  return (
    <Layout>
      <section className="pt-28 bg-white mb-14 pb-8 relative px-4 md:px-16">
        <h1 className="text-pblue-500 text-4xl font-montserrat font-semibold text-center">
          Kontak
        </h1>
        <hr className="w-[70px] mx-auto my-4 border-[1px] border-pblue-500 bg-pblue-500" />
        <h3 className="text-pblue-500 text-2xl font-montserrat font-semibold text-center">
          Pesan
        </h3>
        <form
          action="#"
          className="flex flex-col gap-4 mt-8 max-w-2xl mx-auto font-poppins"
          onSubmit={(ev) => onSubmitEmail(ev)}
        >
          <input
            type="text"
            placeholder="Your name"
            className="px-3 py-2 text-slate-500 bg-lblue-500 border-b-pblue-500 outline-none border-b-2 placeholder:text-slate-300 ring-0 focus:ring-2 focus:ring-pblue-500 focus:border-b-transparent transition-all duration-150"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            className="px-3 py-2 text-slate-500 bg-lblue-500 border-b-pblue-500 outline-none border-b-2 placeholder:text-slate-300 ring-0 focus:ring-2 focus:ring-pblue-500 focus:border-b-transparent transition-all duration-150"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <textarea
            name=""
            id="message"
            cols="30"
            rows="10"
            placeholder="Message"
            className="px-3 py-2 text-slate-500 bg-lblue-500 border-b-pblue-500 outline-none border-b-2 placeholder:text-slate-300 ring-0 focus:ring-2 focus:ring-pblue-500 focus:border-b-transparent transition-all duration-150"
            value={msg}
            onChange={(ev) => setMsg(ev.target.value)}
          ></textarea>
          <button
            type="submit"
            className="font-poppins text-white bg-dblue-500 px-14 py-2 self-center rounded-md mt-4 hover:scale-110 transition-all duration-150"
          >
            {loading ? <Loader /> : "Kirim"}
          </button>
        </form>
        <h3 className="text-pblue-500 text-2xl font-montserrat font-semibold text-center my-8">
          Atau hubungi kami
        </h3>
        <button
          onClick={onClickChatOnWaHandler}
          className="flex gap-2 items-center relative z-10 font-poppins bg-dblue-500 px-8 py-3 rounded-md mx-auto hover:scale-110 transition-all duration-150"
        >
          <img src={WALogo} alt="whatsapp-logo" className="aspect-auto w-6" />
          <span className="text-white">Chat on Whatsapp</span>
        </button>
        <img
          src={OrnB}
          alt="ornamen"
          className="-scale-x-100 absolute z-0 -bottom-56 -left-[80%] md:-left-[60%] md:w-[600px] lg:-left-[40%] xl:-left-[30%] opacity-40"
        />
      </section>
      <EmailModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </Layout>
  );
}
