import Layout from "../components/Layout";
import ServiceItem from "../components/ServiceItem";

export default function Aboutpage() {
  const services = [
    "Konsultasi dan Pengurusan Kuliah",
    "Legalisir Dokumen Kementerian",
    "Terjemah Dokumen Sumpah",
    "Kelas Bahasa Turki",
    "Pengurusan Visa",
    "Konsultan Perjalanan Tour",
  ];

  return (
    <Layout>
      <section
        className="w-full aspect-[9/16] md:h-screen md:aspect-auto px-4 md:px-16 lg:px-24 flex flex-col lg:flex-row items-center justify-center pt-24 pb-14 gap-4 lg:gap-2 relative"
        style={{
          backgroundImage: "url(/assets/img/istanbul.webp)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-full bg-black absolute left-0 top-0 bg-opacity-50"></div>
        <div className="basis-1/2 md:basis-auto lg:basis-1/2 relative">
          <h1 className="text-3xl lg:text-4xl font-montserrat text-white font-semibold">
            Insan Pelajar Mektebi Edu Consultant terpercaya untuk masa depan
            anda
          </h1>
          <p className="text-lg font-montserrat text-lblue-500 mt-6">
            Insan Pelajar Mektebi Edu Consultant terpercaya untuk masa depan
            anda
          </p>
        </div>
        <img
          src="/assets/img/img-4.webp"
          alt="istanbul"
          className="w-full object-cover md:w-4/5 lg:w-1/2 relative"
        />
      </section>
      <section className="w-full bg-lblue-500 flex pt-24 pb-8 justify-center">
        <div className="basis-1/3 lg:basis-1/4 xl:basis-1/3 hidden md:block relative">
          <img
            src="/assets/img/img-5.webp"
            alt=""
            className="w-full h-full object-cover absolute"
          />
        </div>
        <div className="basis-full h-fit md:basis-2/3 lg:basis-1/2 xl:basis-2/3 bg-white px-10 md:px-8 py-6 md:py-14">
          <h2 className="text-pblue-500 text-4xl font-montserrat font-semibold text-center">
            Tentang Kita
          </h2>
          <hr className="border-pblue-500 border-2 bg-pblue-500 w-1/6 mx-auto my-4" />
          <p className="font-poppins text-slate-500 text-center leading-8 text-base md:text-lg">
            Insan Pelajar Mektebi merupakan lembaga konsultan pendidikan
            profesional yang dirintis oleh alumni berpengalaman yang berfokus
            pada jenjang pendidikan menengah ke atas (SMA), perguruan tinggi
            program Sarjana, Master maupun Doktoral di Turki. Insan Pelajar
            Mektebi memberikan pelayanan konsultasi kepada mereka yang ingin
            melanjutkan jenjang pendidikan di Turki tanpa harus ragu memikirkan
            bagaimana kota tempat tinggal, sekolah ataupun kampus tempat belajar
            serta berapa besaran biaya hidup di sana. Insan Pelajar Mektebi
            memudahkan para calon pelajar maupun mahasiswa/i untuk memilih
            sekolah, jurusan, universitas yang sesuai dengan keinginan
            menyesuaikan dengan passing grade, peringkat maupun spesialisasi
            setiap kampus yang akan dipilih.
          </p>
        </div>
        <div className="lg:basis-1/4 xl:basis-1/3 basis-auto hidden md:block relative">
          <img
            src="/assets/img/img-6.webp"
            alt=""
            className="w-full h-full object-cover absolute"
          />
        </div>
      </section>
      <section className="w-full bg-lblue-500 flex flex-col py-14 px-4 md:px-16 lg:px-24 items-center transition-all">
        <h2 className="font-montserrat font-semibold text-pblue-500 text-4xl">
          Layanan Kami
        </h2>
        <ul className="grid grid-cols-2 lg:grid-cols-3 mt-16 gap-8">
          {services.map((service, i) => (
            <ServiceItem key={i} service={service} index={i} />
          ))}
        </ul>
      </section>
    </Layout>
  );
}
